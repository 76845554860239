document.addEventListener("DOMContentLoaded", () => {
    new Swiper(".swiper-banners", {
        lazy: true,
        loop: true,
        autoplay: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
});
